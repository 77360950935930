<template>
  <svg
    stroke="currentColor"
    fill="currentColor"
    stroke-width="0"
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="XMLID_1_">
      <path
        class="st1"
        d="M256,0c141.4,0,256,114.6,256,256S397.4,512,256,512S0,397.4,0,256S114.6,0,256,0z M346.5,366V142.2
		c0-6.2-5-11.2-11.2-11.2h-55.4c-6.2,0-11.2,5-11.2,11.2V366c0,6.2,5,11.2,11.2,11.2h55.4C341.5,377.2,346.5,372.1,346.5,366z
		 M243.3,366V142.2c0-6.2-5-11.2-11.2-11.2h-55.4c-6.2,0-11.2,5-11.2,11.2V366c0,6.2,5,11.2,11.2,11.2h55.4
		C238.3,377.2,243.3,372.1,243.3,366z"
      />
    </g>
  </svg>
</template>
