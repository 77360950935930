<template>
  <div class="terms">
    <h1>TERMS & CONDITIONS</h1>
    <div class="section">
      ______ is a collection of digital artworks (NFTs) running on the Ethereum
      network. This website is only an interface allowing participants to
      exchange digital collectibles. Users are entirely responsible for the
      safety and management of their own private Ethereum wallets and validating
      all transactions and contracts generated by this website before approval.
      Furthermore, as the ______ smart contract runs on the Ethereum network,
      there is no ability to undo, reverse, or restore any transactions.
    </div>

    <div class="section">
      This website and its connected services are provided “as is” and “as
      available” without warranty of any kind. By using this website you are
      accepting sole responsibility for any and all transactions involving
      ______ digital collectibles.
    </div>

    <h1>OWNERSHIP</h1>
    <div class="section">
      i. You own the NFT. Each ___ is an NFT on the Ethereum blockchain. When
      you purchase an NFT, you own the underlying ___, the Art, completely.
      Ownership of the NFT is mediated entirely by the Smart Contract and the
      Ethereum Network: at no point may we seize, freeze, or otherwise modify
      the ownership of any ______ NFT.
    </div>
    <div class="section">
      ii. Personal Use. Subject to your continued compliance with these Terms,
      ______ grants you a worldwide, royalty-free license to use, copy, and
      display the purchased Art, along with any extensions that you choose to
      create or use, solely for the following purposes: (i) for your own
      personal, non-commercial use; (ii) as part of a marketplace that permits
      the purchase and sale of your ___ / NFT, provided that the marketplace
      cryptographically verifies each ___ owner’s rights to display the Art for
      their ______ NFT to ensure that only the actual owner can display the Art;
      or (iii) as part of a third party website or application that permits the
      inclusion, involvement, or participation of your ___, provided that the
      website/application cryptographically verifies each ______ owner’s rights
      to display the Art for their ______ NFT to ensure that only the actual
      owner can display the Art, and provided that the Art is no longer visible
      once the owner of the ______ NFT leaves the website/application.
    </div>

    <div class="section">
      iii. Commercial Use. Subject to your continued compliance with these
      Terms, ______ grants you an unlimited, worldwide license to use, copy, and
      display the purchased Art for the purpose of creating derivative works
      based upon the Art (“Commercial Use”). Examples of such Commercial Use
      would e.g. be the use of the Art to produce and sell merchandise products
      (T-Shirts etc.) displaying copies of the Art. For the sake of clarity,
      nothing in this Section will be deemed to restrict you from (i) owning or
      operating a marketplace that permits the use and sale of ___ NFTs
      generally, provided that the marketplace cryptographically verifies each
      ___ NFT owner’s rights to display the Art for their ___ NFT to ensure that
      only the actual owner can display the Art; (ii) owning or operating a
      third party website or application that permits the inclusion,
      involvement, or participation of ___ generally, provided that the third
      party website or application cryptographically verifies each ___ NFT
      owner’s rights to display the Art for their ___ NFT to ensure that only
      the actual owner can display the Art, and provided that the Art is no
      longer visible once the owner of the Purchased ___ NFT leaves the
      website/application; or (iii) earning revenue from any of the foregoing.
    </div>
  </div>
</template>
<script>
import { useRouter } from 'vue-router';
export default {
  mounted() {
    window.scroll(0, 0);
  },

  setup() {
    const router = useRouter();
    const goBack = () => {
      router.go(-1);
    };

    return {
      goBack
    };
  }
};
</script>
<style scoped lang="scss">
.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: rgb(29, 29, 29);
  padding: 10px 0px;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  animation-duration: 7s;
  animation-name: rainbowBorder;
  animation-iteration-count: infinite;

  align-items: center;
  button {
    margin-left: 60px;
    font-size: 14px;
    font-family: PressStart;
    animation-duration: 10s;
    animation-name: rainbowText, rainbowBorder;
    animation-iteration-count: infinite;
    width: fit-content;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid white;
    transition: background-color 200ms ease-in;
    color: white;
    cursor: pointer;
    background-color: transparent;

    &:hover {
      animation-name: rainbowBackground;
    }

    &:active {
      animation-name: none;
      background-color: rgb(255, 0, 191);
    }
  }

  img {
    height: 100px;
    width: 200px;
    justify-self: center;
  }
}
.terms {
  padding: 60px;
  max-width: 1600px;
  color: white;
  text-align: center;
  margin: auto;
  height: 100%;
  h1 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .section {
    margin-bottom: 20px;
    font-size: 16px;
  }
}
</style>
