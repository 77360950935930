'use strict';

// Specified state transitions per particular actions.
export default {
  // Update the list of launchpad items being tracked for display purposes.
  updateShop(state, shop) {
    state.shop = shop;
  },

  updateBucks(state, bucks) {
    state.bucks = bucks;
    state.ownsBuck = bucks.length > 0;
  },

  updateClaimStatus(state, status) {
    state.merkleClaimedStatus = status;
  },

  updateIndex(state, index) {
    state.merkleUserIndex = index;
  },

  updateTimes(state, times) {
    state.presaleTimes = times.presaleStarts;
    state.startDate = times.publicStart;
  },

  updateAllowances(state, allowances) {
    state.allowances = allowances;
  },

  updateProofs(state, userProofs) {
    state.merkleProofs = userProofs;
  }
};
