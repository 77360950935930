<template>
  <div class="splashArea">
    <!-- <div class="rainbowWrapper">
      <img src="../../../images/rainbow.png" />
      <img class="mobile" src="../../../images/rainbow-mobile.png" />
    </div> -->

    <!-- <div class="buildingWrapper">
      <img src="../../../images/buildings.png" />
      <img class="mobile" src="../../../images/buildings-mobile.png" />
    </div> -->

    <!-- <div class="mintWrapper">
      <img src="../../../images/mint-your-techno.png" />
    </div> -->

    <div class="pigsWrapper">
      <img src="../../../images/pigs-3.png" />
      <img class="mobile" src="../../../images/pigs.png" />
    </div>



    <div class="speechWrapper">
          <a href="https://stems.ravepigs.com/" target="_blank" class="download-link">Download Stems</a>
      <div class="wrapper">
        <!-- <img class="bubble" src="../../../images/speech-bubble.png" /> -->
        <!-- <img class="mobile" src="../../../images/bubble.png" /> -->

        <img class="arrow" src="../../../images/down-arrow.png" />
        <div class="text">
          <p>
            <span>
              <strong class="rave-pigs-font">Rave Pigs</strong> is a collection
              of 6,666 individually unique, generative audio-visual characters
              from the dark and dirty Berlin underground letting you
              <strong>mint and own techno tracks</strong> from the one and only
              <strong>Boys Noize</strong>!
            </span>
            <br />
            <br />
            <span>
              <strong class="rave-pigs-font">Rave Pigs</strong> give collectors
              ownership rights to <strong>everything you see and hear</strong>.
              Each <strong class="rave-pigs-font">Rave Pig</strong> character is
              assembled through combinations of 129 traits spread out over 10
              visual categories and 5 audio layers for over
              <strong>50 TRILLION possible combinations</strong>.
            </span>
            <br />
            <br />
            <span>
              With illustrations by a
              <strong>legendary anonymous Berlin graffiti writer</strong>
              (you’ve seen his work!), and banging music from
              <strong>Grammy Award winning techno</strong> DJ and producer
              <strong>Boys Noize</strong>, your
              <strong class="rave-pigs-font">Rave Pigs</strong> can be displayed
              confidently as a badge of true hedonistic expertise!
            </span>
            <br />
            <br />
            <span>
              <strong class="rave-pigs-font">Rave Pigs </strong> are stored as
              <strong>ERC-1155</strong> tokens on the Ethereum blockchain and
              can be downloaded in <strong>.png</strong> and
              <strong>.mp4</strong> formats.
            </span>

            <br />
            <br />
            <span>
              <strong class="rave-pigs-font">Rave Pigs </strong> holders will
              <strong>own the rights to the artwork and music</strong> in their
              NFT. Additionally, holders will be given the audio stems to their
              NFT to use in
              <strong>any of their own compositions and remixes</strong>,
              opening up a collaborative network of
              <strong class="rave-pigs-font">Rave Pigs </strong> across the
              world. <strong class="rave-pigs-font">Rave Pigs </strong> are not,
              however, responsible for any damages caused by sleeplessness,
              hangovers, excessive raging, illegal substances, or damaged hotel
              rooms.
            </span>

            <br />
            <br />
            <span>
              <strong class="rave-pigs-font">Rave Pigs </strong> will be minted
              on our website for <strong>Ξ0.0909</strong> and will be tradable
              on secondary markets like OpenSea. <strong>150 </strong>
              <strong class="rave-pigs-font">Rave Pigs </strong> will be
              retained for marketing, promotions, giveaways, and team.
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import router from '/src/router';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';


// Component imports.

// Icon imports.

export default {


  setup() {
    const store = useStore();
    const song = ref(null);
    const navigateTo = route => {
      router.push(route);
    };

    const toggleSong = () => {
      console.log(song);
      song.value.play();
    };

    return {
      navigateTo,
      toggleSong,
      store
    };
  }
};
</script>
<style scoped lang="scss">

  .download-link {
cursor: pointer;
    padding: 15px 20px;
    border-radius: 10px;
    transition: box-shadow 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
    color: #fff;
    background-color: #f277ad;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    letter-spacing: 3px;
    text-decoration: none;
    text-transform: uppercase;
    border: none;
    margin-left: 0;
position: absolute;
    top: 44px;
     left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 768px) {
         top: -5px;
    left: 50%;
    transform: translateX(-50%);
    }
  }

.splashArea {
  width: 100vw;
  height: 100vh;
  position: relative;

  .logoWrapper {
    max-width: 500px;
    min-width: 300px;
    min-height: 100%;

    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);

    position: absolute;
    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  .rainbowWrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;

    img {
      width: 100%;
      height: 100%;
    }

    .mobile {
      display: none;
    }
  }

  .buildingWrapper {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: absolute;

    img {
      width: 100%;
      height: 100%;
    }

    .mobile {
      display: none;
    }
  }

  .mintWrapper {
    width: 400px;
    bottom: 42px;
    left: 64px;
    transform: rotate(-10deg);
    position: absolute;
    z-index: 21;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .pigsWrapper {
    width: 100vw;
    max-width: 2000px;
    bottom: -20px;
    right: 0px;
    position: absolute;
    animation: bounce infinite 1.25s ease;


    @keyframes bounce {
      0% {
        bottom: -20px;
      }

      50% {
        bottom: -5px;
      }

      100% {
        bottom: -20px;
      }
    }

    img {
      width: 100%;
      height: 100%;
          @media screen and (min-width: 768px) {
        // max-width: 75%;
        width: 100%;
        margin: 0 auto;
        display: block;
    }

    }

    .mobile {
      display: none;
    }
  }

  .speechWrapper {
    width: 630px;
    height: 500px;
    max-height: 55vh;
    top: 60%;
    left: 55%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 20;

    @media screen and (min-width: 768px) {
          width: 630px;
    height: 500px;
    max-height: 55vh;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 20;
    }

    .button {
      width: fit-content;
      margin-left: -10vw;
      animation: rainbowButton 4s infinite;
      box-shadow: 0px 0px 20px white;
      border: 3px solid white;
    }

    .mobile {
      display: none;
    }
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      // background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #58cce4;
      cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      opacity: 0.8;
    }

    .wrapper {
      height: 100%;
      width: 100%;
      position: relative;
      z-index: -1;

      @media screen and (max-width: 768px) {
        margin-top: 100px;
      }



      .text {
        width: 270px;
        color: #58cce4;
        height: 255px;
        overflow-y: scroll;
        text-align: center;
        font-family: Ropa Sans, sans-serif;
        font-size: 16px;
        position: absolute;
        top: 22%;
        left: 110px;
        z-index: 2;
        line-height: 1.5;
        padding: 0 10px;

        .rave-pigs-font {
          font-family: 'DK Cool Crayon Regular';
          font-weight: 700;
        }

        @media screen and (min-width: 768px) {

    left: 52%;
    z-index: 2;
    line-height: 1.5;
    padding: 0 10px;
    transform: translateX(-50%);
    background: rgba(161,26,231, 0.8);
    padding: 40px;
    border-radius: 25px;
    width: 59%;
    height: 281px;
    top: 44%;
        }
      }

      .arrow {
        position: absolute;
        bottom: 70px;
        left: 50px;
        z-index: 2;
        height: 50px;
        object-fit: contain;
        width: 50px;
        animation: arrowbounce infinite 1.25s ease;
        display: none;

        @keyframes arrowbounce {
          0% {
            bottom: 70px;
          }

          50% {
            bottom: 80px;
          }

          100% {
            bottom: 70px;
          }
        }
      }

      .bubble {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1180px) {
  .splashArea {
    .mintWrapper {
      display: none;
    }
  }
}

@media (max-width: 600px) {
  .splashArea {
    .speechWrapper {
      width: 370px;
      height: 250px;

      top: 55%;
      left: 50%;
      transform: translate(-50%, -50%);
      .button {
        margin-left: 0;
      }

      .bubble {
        display: none;
      }

      .mobile {
        display: initial;
        width: 100%;
        height: 100%;
      }

      .wrapper {
        .text {
       top: 72px;
    left: 80px;
    width: 240px;
    height: 163px;
        }

        .arrow {
          left: 45px;
          height: 40px;
          top: 70px;
          display: block;
        }
      }
    }

    .pigsWrapper {
      img {
        display: none;
      }

      .mobile {
        display: initial;
      }
    }

    .logoWrapper {
      top: 25px;
      max-width: 500px;
      min-width: 100px;
      min-height: 100%;
    }

    .buildingWrapper {
      img {
        display: none;
      }

      .mobile {
        display: initial;
      }
    }

    .rainbowWrapper {
      height: 100%;
      object-fit: contain;
      img {
        display: none;
      }

      .mobile {
        display: initial;
        padding-top: 30px;
      }
    }
  }
}
</style>

<style scoped>
/* Fade animation */
.fade-enter-active,
.fade1-enter-active,
.fade2-enter-active {
  transition: all 1000ms ease;
}

.fade-enter-from,
.fade1-enter-from,
.fade2-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade1-enter-active {
  transition-delay: 400ms;
}

.fade2-enter-active {
  transition-delay: 800ms;
}
</style>
