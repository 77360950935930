<template>
  <Ethereum :callback="initialize" />
  <div class="mint">
    <div class="heading">Mint Your Rave Pigs</div>
    <p class="text">6,666 Pigs Total</p>
    <div class="imageContainer">
      <img src="https://apes-r-us.s3.amazonaws.com/sample-ape.png" />
    </div>

    <div class="text" v-if="!store.state.ethers.canSign">
      Please Connect Wallet Above.
    </div>

    <div class="buyWrapper" v-else>
      <p class="text">Eligible: {{ isWhitelisted }}</p>
      <p class="text">Whitelist Allocations: {{ whitelists.length }}</p>
      <p class="text">{{ quantityRemaining }} Pigs Remaining</p>

      <div
        class="dropdownWrapper"
        v-if="isLive && quantityRemaining > 0 && isWhitelisted"
      >
        <div
          class="mintWrapper"
          v-for="(time, index) in presaleTimes"
          :key="index"
        >
          <p>presale whitelist #{{ time.id }}</p>
          <div
            v-if="time.startTime > new Date()"
            style="border: 1px solid #777; border-radius: 10px; padding: 15px"
          >
            <p style="text-align: center" class="text">Minting Beginning In:</p>
            <Countdown
              :startDate="time.startTime"
              :endDate="time.endTime"
            ></Countdown>
          </div>
          <div class="dropdown" v-else>
            <!--
          <DropdownMenu
            class="dropdown"
            :selection="time.id"
            :items="whitelists"
            @selected="handleWLSelection(index, $event)"
          />-->
            <DropdownMenu
              class="dropdown"
              :selection="number[time.id]"
              :items="numbers[time.id]"
              @selected="handleSelection(index, $event, time.id)"
            />
            <Button @click="purchaseItem(time.id)">Mint</Button>
          </div>
        </div>

        <div v-if="!isConnected">Please connect your wallet.</div>
      </div>
      <div v-else-if="isLive && quantityRemaining === 0">
        <p class="text">Sold Out!</p>
      </div>
    </div>
  </div>
</template>
<script>
// Imports.
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

// Component imports.
import DropdownMenu from '/src/components/ui/DropdownMenu.vue';
import Button from '/src/components/ui/Button.vue';
import Ethereum from '/src/components/common/Ethereum.vue';
import Countdown from '/src/components/ui/Countdown.vue';

export default {
  components: {
    DropdownMenu,
    Button,
    Ethereum,
    Countdown
  },

  setup() {
    const store = useStore();

    const handleSelection = (index, selection, whiteListId) => {
      //whiteListId = selection;
      console.log('number.value', selection, number.value, whiteListId);
      number.value[whiteListId] = selection;
    };
    const handleWLSelection = (index, selection) => {
      whiteListId.value = selection;
    };

    onMounted(() => {
      window.scroll(0, 0);
    });

    const isConnected = computed(() => {
      return store.state.ethers.canSign;
    });

    let now = ref(new Date());
    setInterval(() => {
      now.value = new Date();
    }, 1000);

    const isLive = computed(() => {
      return store.state.mint.startDate < now.value;
    });

    // const presaleTimes = computed(() => {
    //   // let times = [];
    //   // for()
    //   return store.state.mint.presaleTimes;
    // });
    // const purchaseItem = async () => {
    //   await store.dispatch(
    //     'mint/purchaseItem',
    //     {
    //       poolId: 0,
    //       groupId: 1,
    //       assetId: 0,
    //       amount: number.value
    //     },
    //     { root: true }
    //   );
    // };

    const purchaseItem = async id => {
      console.log('number.value[whiteListId.value]', number.value[id]);
      await store.dispatch(
        'mint/whitelistedPurchaseItem',
        {
          whitelistId: id,
          poolId: 0,
          groupId: 1,
          amount: number.value[id]
        },
        { root: true }
      );

      await store.dispatch(
        'mint/getMerkleData',
        { groupId: 1 },
        { root: true }
      );
    };

    const initialize = async () => {
      // Retrieve the number of NFTs from the store.
      if (store.state.ethers.canSign) {
        store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
          root: true
        });

        await store.dispatch(
          'mint/getMerkleData',
          { groupId: 1 },
          { root: true }
        );

        // Poll remaining NFTs from the store.
        setInterval(() => {
          store.dispatch('mint/getShopItems', store.state.mint.shopAddress, {
            root: true
          });
        }, 15000);
        presaleTimes.value = store.state.mint.presaleTimes;
        console.log('presale time', store.state.mint.presaleTimes);
        allowances.value = store.state.mint.allowances;
      }
    };

    watch(
      () => store.state.ethers.canSign,
      selection => {
        initialize();
      }
    );

    const number = computed(() => {
      let initAmounts = [];
      for (let time of store.state.mint.presaleTimes) {
        //let time = store.state.mint.presaleTimes[i]
        console.log('time', time.id);
        initAmounts[time.id] = 1;
      }
      console.log('initAmounts', initAmounts);
      return initAmounts;
    });

    const numbers = computed(() => {
      let amounts = [];
      for (let i = 0; i <= store.state.mint.presaleTimes.length; i++) {
        let allocations = [];
        for (let n = 1; n <= store.state.mint.allowances[i]; n++) {
          allocations.push(n);
        }
        amounts[i] = allocations;
      }
      return amounts;
    });

    const allowances = ref([]);
    const presaleTimes = ref([]);
    const whiteListId = ref(0);
    //const whitelists = ref([]);
    const whitelists = computed(() => {
      return Object.keys(store.state.mint.allowances);
    });

    const isWhitelisted = computed(() => {
      return whitelists.value.length > 0;
      //return store.state.mint.merkleProofs.length > 0;
    });

    const quantityRemaining = computed(() => {
      if (store.state.mint.shop) {
        let item = store.state.mint.shop.pools[0].items[0];
        return item.cap.toNumber() - item.minted.toNumber();
      }
      return -1;
    });

    return {
      allowances,
      isConnected,
      isLive,
      isWhitelisted,
      number,
      numbers,
      handleSelection,
      handleWLSelection,
      initialize,
      presaleTimes,
      purchaseItem,
      quantityRemaining,
      store,
      whiteListId,
      whitelists
    };
  }
};
</script>
<style scoped lang="scss">
.mint {
  padding: 60px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;

  .buyWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .mintWrapper {
      margin-bottom: 20px;

      p {
        margin-bottom: 10px;
      }
    }

    .dropdownWrapper {
      .dropdown {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-right: 10px;

        button {
          cursor: pointer;
          padding: 5px 20px;
          border-radius: 10px;
          height: 40px;
          -webkit-transition: box-shadow 200ms
              cubic-bezier(0.215, 0.61, 0.355, 1),
            -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
            -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
            transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
            transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
            -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
          color: white;
          background-color: #f277ad;
          font-size: 18px;
          font-weight: 600;
          text-align: center;
          letter-spacing: 3px;
          text-decoration: none;
          text-transform: uppercase;
          border: none;
        }

        button:hover {
          box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
          -webkit-transform: translate(0px, -2px) scale(1.05);
          -ms-transform: translate(0px, -2px) scale(1.05);
          transform: translate(0px, -2px) scale(1.05);
          color: white;
        }

        button:active {
          box-shadow: none;
          -webkit-transform: none;
          -ms-transform: none;
          transform: none;
        }
      }
    }

    .heading {
      margin-top: 0px;
      margin-bottom: 20px;
      font-size: 40px;
      line-height: 1em;
      font-weight: 700;
      text-align: center;
      text-transform: uppercase;
      text-shadow: 0 0 2px white;
    }

    .text {
      margin-bottom: 20px;
      font-weight: 700;
      font-size: 18px;
      text-shadow: 0 0 2px white;
    }

    .imageContainer {
      max-width: 250px;
      max-height: 250px;
      width: 100%;
      height: 100%;
      margin-bottom: 30px;
      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: 5px;
        border: 2px solid white;
      }
    }
  }

  .mintWrapper {
    margin-bottom: 20px;

    p {
      margin-bottom: 10px;
    }
  }

  .dropdownWrapper {
    .dropdown {
      display: grid;
      grid-template-columns: auto 1fr;
      margin-right: 10px;

      button {
        cursor: pointer;
        padding: 5px 20px;
        border-radius: 10px;
        height: 40px;
        -webkit-transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        transition: box-shadow 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1),
          -webkit-transform 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: white;
        background-color: #f277ad;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        letter-spacing: 3px;
        text-decoration: none;
        text-transform: uppercase;
        border: none;
      }

      button:hover {
        box-shadow: 0 14px 40px 0 rgba(0, 0, 0, 0.2);
        -webkit-transform: translate(0px, -2px) scale(1.05);
        -ms-transform: translate(0px, -2px) scale(1.05);
        transform: translate(0px, -2px) scale(1.05);
        color: white;
      }

      button:active {
        box-shadow: none;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
      }
    }
  }

  .heading {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 40px;
    line-height: 1em;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 2px white;
  }

  .text {
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    text-shadow: 0 0 2px white;
  }

  .imageContainer {
    max-width: 250px;
    max-height: 250px;
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      border: 2px solid white;
    }
  }
}

@media (max-width: 700px) {
  .mint .heading {
    font-size: 12vw;
  }
}
</style>
