'use strict';

// Import our module information.
import actions from './actions';
import mutations from './mutations';

// Prepare state.
const state = {
  // startDate: new Date(1626897600 * 1000),
  shopAddress: '0x3eF559b1158A3817CF3951Ba53Bce65eD78C1E71',
  shop: null,
  bucks: [],
  ownsBuck: false,
  merkleUserIndex: '',
  merkleClaimedStatus: true,
  merkleProofs: [],
  allowances: [],
  presaleTimes: [],
  startDate: 0
};

// Return the module prepared for use.
export default {
  namespaced: true,
  state,
  actions,
  mutations
};
