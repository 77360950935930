<template>
  <Ethereum :callback="initialize" />
  <SplashArea />
  <!-- <AboutSection id="about" />
  <ImagesSection id="roadmap" />
  <FAQSection id="faqs" />
  <TeamSection id="team" />
  <BottomSection id="bottom" /> -->
  <!-- <Mint /> -->
</template>
<script>
import Ethereum from '/src/components/common/Ethereum.vue';
// import Mint from '/src/pages/home/components/Mint.vue';
import SplashArea from './components/SplashArea.vue';
import AboutSection from './components/AboutSection.vue';
import FAQSection from './components/FAQSection.vue';
import ImagesSection from './components/ImagesSection.vue';
import TeamSection from './components/TeamSection.vue';
import BottomSection from './components/BottomSection.vue';

import { computed, ref, onMounted, watch } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
    Ethereum,
    // Mint,
    SplashArea
    // AboutSection,
    // ImagesSection,
    // FAQSection,
    // TeamSection,
    // BottomSection
  },

  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => {
      return store.state.ethers.address;
    });

    const initialize = async () => {};

    return {
      store,
      isLoggedIn,
      initialize
    };
  }
};
</script>
<style scoped lang="scss">
.home {
  text-align: center;
  padding: 50px;
}
</style>
