'use strict';

// Imports.
import initializeConfig from '../initialize-config';
import { ethersService } from './index';
import { ethers } from 'ethers';
import axios from 'axios';

// Initialize this service's configuration.
let config;
(async () => {
  config = await initializeConfig();
})();

// A helper function to parse pool output data.
const parsePool = async function(provider, pool) {
  let poolItems = [];
  for (let i = 0; i < pool.items.length; i++) {
    let item = pool.items[i];
    let itemPrices = [];
    for (let j = 0; j < item.prices.length; j++) {
      let pricePair = item.prices[j];
      itemPrices.push({
        assetType: pricePair.assetType,
        asset: pricePair.asset,
        price: pricePair.price
      });
    }
    // Temp fix ask Tom
    // let metadataId = item.groupId
    //   .shl(128)
    //   ._hex.substring(2)
    //   .padStart(64, '0');

    // @imran you were really close, just needed toHexString() -love, tom
    let metadataId = item.groupId  // 0x01
      .shl(128) // 0x0100000000000000000000000000000000
      .add(1) // 0x0100000000000000000000000000000001
      .toHexString() // "0x0100000000000000000000000000000000"
      .substring(2) // "0100000000000000000000000000000000"
      .padStart(64, '0'); // "0000000000000000000000000000000100000000000000000000000000000001"

    let itemMetadataUri = pool.itemMetadataUri.replace('{id}', metadataId);

    //let metadataResponse = await axios.get(itemMetadataUri);
    //let metadata = metadataResponse.data;
    poolItems.push({
      metadataUri: itemMetadataUri,
      metadata: "",//metadata,
      groupId: item.groupId,
      cap: item.cap,
      minted: item.minted,
      prices: itemPrices
    });

  }

  return {
    name: pool.config.name,
    startTime: pool.config.startTime,
    endTime: pool.config.endTime,
    requirement: {
      requiredType: pool.config.requirement.requiredType,
      requiredAsset: pool.config.requirement.requiredAsset,
      requiredAmount: pool.config.requirement.requiredAmount
    },
    items: poolItems
  };
};

const getPublicStartTime = async function(poolId) {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let pools = await shopContract.pools(poolId);

  let startTime = new Date(pools.config.startTime.toString() * 1000)

  return startTime
}

// Fetch all available launchpad items.
const getShopItems = async function(shopAddress) {
  if (!config) {
    config = await initializeConfig();
  }
  let provider = await ethersService.getProvider();

  let isValid = await ethers.utils.isAddress(shopAddress);
  if (!isValid) {
    return {};
  }

  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    provider
  );

  let pools = await shopContract.getPools([0], 0);
  let poolA = await parsePool(provider, pools[0]);
    //console.log("fetching pools", poolA)
  poolA.id = 0;
  return {
    pools: [poolA]
  };
};

// Purchase an item from the shop.
const purchaseItem = async function(
  poolId,
  groupId,
  amount,
  dispatch
) {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let shopAddress = config.shopAddress[networkId];

  let isValid = await ethers.utils.isAddress(shopAddress);
  if (!isValid) {
    return; // TODO: throw useful error.
  }

  // Check for token spend approval.
  let signer = await provider.getSigner();
  let address = await signer.getAddress();

  // Purchase the item.
  let shopContract = new ethers.Contract(
    shopAddress,
    config.mintShopABI,
    signer
  );

  //let price = ethers.utils.parseEther('0.06');
  let pools = await shopContract.getPools([poolId], 0);
  let price = await pools[poolId].items[0].prices[0].price;
console.log("priceprice", price, pools[poolId])
  let totalSpend = price.mul(amount);
  let usernode = ethers.utils.solidityKeccak256(["uint256", "address", "uint256"], [1, ethers.constants.AddressZero, 0]);

  let whiteListInput = {
    whiteListId: 0,
    index: 0,
    allowance: amount,
    node: usernode,
    merkleProof: [usernode,usernode,usernode]
  }

  try {
    let purchaseTransaction = await shopContract.mintFromPool(
      poolId,
      groupId,
      0, // price index
      amount,
      0, // item index
      whiteListInput,
      { value: totalSpend }
    );

    purchaseTransaction.wait().then(async result => {
      await dispatch('alert/clear', '', { root: true });
      await dispatch(
        'alert/info',
        {
          message: 'Transaction Confirmed',
          duration: 10000
        },
        { root: true }
      );
      await dispatch('mint/getBucks', '', { root: true });
      await dispatch('windows/close', { label: '' }, { root: true });
      setTimeout(async () => {
        await dispatch('windows/open', { label: 'Member' }, { root: true });
      }, 2000);
    });

    await dispatch(
      'alert/info',
      {
        message: 'Transaction Submitted',
        metadata: {
          transaction: purchaseTransaction.hash
        },
        duration: 300000
      },
      { root: true }
    );

    // Return a user-friendly error for the purchase failure.
  } catch (error) {
    console.error("ERROR", error);
    throw error;
  }
};

// Parse the items owned by a given address.
const processItems = async function(
  contractAddress,
  userAddress,
  groupId,
  provider
) {
  if (!config) {
    config = await initializeConfig();
  }
  let itemContract = new ethers.Contract(
    contractAddress,
    config.itemABI,
    provider
  );

  // Check relative item transfer events to determine this user's current
  // inventory.
  let ownershipData = {};
  let batchTransfers = await itemContract.queryFilter('TransferBatch');
  // TokenRedemption
  console.log(`Processing ${batchTransfers.length} batch transfer events ...`);
  for (let i = 0; i < batchTransfers.length; i++) {
    let batchTransfer = batchTransfers[i];
    let blockNumber = batchTransfer.blockNumber;
    let itemIds = batchTransfer.args.ids;
    let to = batchTransfer.args.to;
    let amounts = batchTransfer.args[4];
    for (let j = 0; j < itemIds.length; j++) {
      let itemId = itemIds[j];
      let transferredId = itemId.shr(128);

      if (groupId != parseInt(transferredId) && groupId != 0) {
        break;
      }
      let itemAmount = amounts[j];
      if (ownershipData[itemId.toString()]) {
        if (ownershipData[itemId.toString()].block <= blockNumber) {
          ownershipData[itemId.toString()] = {
            itemId: itemId,
            amount: itemAmount,
            owner: to,
            block: blockNumber
          };
        }
      } else {
        ownershipData[itemId.toString()] = {
          itemId: itemId,
          amount: itemAmount, // TODO: likely wrong
          owner: to,
          block: blockNumber
        };
      }
    }
  }

  // Single transfer events.
  let singleTransfers = await itemContract.queryFilter('TransferSingle');
  console.log(
    `Processing ${singleTransfers.length} single transfer events ...`
  );
  for (let i = 0; i < singleTransfers.length; i++) {
    let singleTransfer = singleTransfers[i];
    let blockNumber = singleTransfer.blockNumber;
    let itemId = singleTransfer.args.id;
    let transferredId = itemId.shr(128);
    if (groupId != parseInt(transferredId) && groupId != 0) {
      break;
    }
    let to = singleTransfer.args.to;
    let amount = singleTransfer.args.value;
    if (ownershipData[itemId.toString()]) {
      if (ownershipData[itemId.toString()].block <= blockNumber) {
        ownershipData[itemId.toString()] = {
          itemId: itemId,
          amount: amount,
          owner: to,
          block: blockNumber
        };
      }
    } else {
      ownershipData[itemId.toString()] = {
        itemId: itemId,
        amount: amount,
        owner: to,
        block: blockNumber
      };
    }
  }

  // Process the item event history to find owned items.
  let metadataUri = await itemContract.metadataUri({
    gasLimit: ethers.BigNumber.from('14500000')
  });
  let ownedItems = [];
  for (const itemId in ownershipData) {
    let itemOwnershipData = ownershipData[itemId];
    if (itemOwnershipData.owner === userAddress) {
      let group = parseInt(itemOwnershipData.itemId.shr(128));
      let metadataId = itemOwnershipData.itemId._hex
        .substring(2)
        .padStart(64, '0');

      let itemMetadataUri = metadataUri.replace('{id}', metadataId);
      let metadataResponse = await axios.get(itemMetadataUri);
      let metadata = metadataResponse.data;

      if (metadata.image.substring(0, 7) == 'ipfs://') {
        metadata.image = `http://ipfs.io/ipfs/${metadata.image.substring(7)}`;
      }

      ownedItems.push({
        id: metadataId,
        gid: group,
        metadataUri: itemMetadataUri,
        metadata: metadata,
        balance: itemOwnershipData.amount,
        cap: ethers.BigNumber.from(10000)
      });
    }
  }
  console.log('ownedItems', ownedItems);
  return ownedItems;
};

// Fetch all items owned by a particular address.
const getOwnedItems = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);

  let ownedItems = [];
  let itemCollectionAddress = config.itemCollections[networkId][0];

  let groupId = 0;
  let isValid = await ethers.utils.isAddress(itemCollectionAddress);
  if (isValid) {
    ownedItems = ownedItems.concat(
      await processItems(itemCollectionAddress, address, groupId, provider)
    );
  }

  return ownedItems;
};

const samplePaymentSplitter = async function() {
  if (!config) {
    config = await initializeConfig();
  }

  let provider = await ethersService.getProvider();
  let signer = await provider.getSigner();
  let address = await signer.getAddress();
  let network = await provider.getNetwork();
  let networkId = ethers.utils.hexValue(network.chainId);
};

// Export the user service functions.
export const mintService = {
  getShopItems,
  getPublicStartTime,
  purchaseItem,
  getOwnedItems,
  samplePaymentSplitter
};
